import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="active last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/veelgestelde-vragen-diarree/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/faq-questions-diarrhee/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="6b8b5787eb3f804a9e5dbc19a3c39f3cd5a35cd1"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/veelgestelde-vragen-diarree/"> FAQ</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Als het gaat om de behandeling van de symptomen van diarree,
                  zijn er nog veel onzekerheden. Lees enkele tips.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Reisapotheek</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Reisapotheek"
                  />
                </div>
                <p>
                  Wat hoort thuis in de <strong>reisapotheek</strong>? Vind hier
                  alle praktische informatie.
                </p>
                <a href="/diarree-op-reis/reisapotheek/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-45">
            <h1>Veelgestelde vragen over diarree</h1>
            <div className="intro row">
              <div className="twelve columns">
                <p>
                  <a
                    data-close="Alles sluiten"
                    data-open="Alles openklappen"
                    href="#"
                    id="toggleAllQuestions"
                  >
                    Alles openklappen
                  </a>
                </p>
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/oorzaak-terugkerende-diarree/">
                  Wat moet ik doen als ik niet zeker weet waardoor mijn
                  terugkerende diarree wordt veroorzaakt?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Als uw <strong>diarree</strong> blijvend of terugkerend is of
                  gepaard gaat met bloed of andere symptomen, moet u uw arts
                  raadplegen – vooral als u onlangs in een minder ontwikkeld
                  land bent geweest. Als er geen duidelijke reden is voor uw
                  diarree en u lijkt geen van de oorzaken te hebben die op deze
                  website beschreven staan, kan het te wijten zijn aan
                  voedselintolerantie, of misschien gewoon aan stress.{" "}
                  <strong>IMODIUM®</strong> kan in een aantal van deze gevallen
                  helpen, maar het is belangrijk eerst advies te vragen aan uw
                  arts of apotheker.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/geschikte-behandeling-diarree-zonder-inslikken-tabletten/">
                  Welke behandeling is voor mij geschikt als ik hopeloos ben in
                  het inslikken van tabletten?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Maak je geen zorgen, wanneer diarree de kop opsteekt kan je
                  nog altijd snel verlichting krijgen met IMODIUM® Instant
                  orodispergeerbare tabletten. Deze tabletten zijn zodanig
                  gemaakt dat ze binnen enkele seconden op je tong smelten,
                  zonder dat je water nodig hebt.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt/">
                  Waarom lijk ik altijd diarree te hebben wanneer ik grote
                  hoeveelheden eet en drink?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Wanneer je te veel eet en drinkt, moet je spijsvertering te
                  snel werken, wat <strong>diarree</strong>&nbsp;kan
                  veroorzaaken. Probeer dus altijd verstandig te eten en vermijd
                  overdaad! Als je toch diarree krijgt, kan één dosis{" "}
                  <strong>IMODIUM® Instant orodispergeerbare tabletten of IMODIUM® Capsules</strong> de symptomen 
				  snel verlichten en je weer op de been helpen.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen/">
                  Wat moet ik doen als mijn kinderen diarree krijgen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  <strong>Kinderen</strong> zijn minder goed in staat om te gaan
                  met verlies van lichaamsvocht door <strong>diarree</strong>{" "}
                  dan volwassenen; het belangrijkste is dus het vocht snel
                  opnieuw op peil te brengen. Je apotheker kan je orale
                  rehydratatiebehandelingen geven, maar als het probleem blijft
                  aanhouden of als het kind jonger is dan 6 jaar, is het
                  aangeraden uw arts te raadplegen.
                </p>
                <p>
                  Kinderen ouder dan 6 jaar kunnen worden behandeld met
                  IMODIUM®.
                </p>
                <p>
                  In ons hoofdstuk{" "}
                  <a href="/diarree-bij-kinderen/">diarree bij kinderen</a>
                  &nbsp;vind je meer informatie hierover.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen/">
                  Ik schaam me om een geneesmiddel tegen diarree te vragen bij
                  de apotheker. Wat kan ik doen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Diarree kan op veel manieren onaangenaam zijn, vooral als je
                  bij de apotheker staat en er staat nog een hele rij wachtenden
                  achter je. Maar denk eraan dat diarree een van de meest
                  voorkomende klachten is waarmee je apotheker te maken krijgt.
                  Apothekers zijn opgeleid om je professioneel advies te geven
                  en begrip te tonen en zij kunnen je de juiste
                  IMODIUM®-behandeling aanbevelen. Als je jezelf erg schaamt,
                  waarom vraag je dan niet of je de apotheker privé kunt
                  spreken?
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-krijg-je-diarree-tijdens-je-maandstonden/">
                  Waarom krijg ik vaak diarree tijdens mijn maandstonden?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Helaas krijgen veel vrouwen diarree tijdens hun menstruatie.
                  Er wordt verondersteld dat dit wordt veroorzaakt door
                  hormonale wijzigingen. Maar laat het je maand niet vergallen;
                  je kunt de symptomen efficiënt behandelen met
                  IMODIUM®.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij{" "}
                  <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                    diarree tijdens je menstruatie.
                  </a>
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel/">
                  Mijn maag reageert vaak gevoelig op sterk gekruid voedsel. Wat
                  kan ik daaraan doen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Sterk gekruid voedsel is een veel voorkomende oorzaak van
                  diarree – sommige spijsverteringsstelsels kunnen dit gewoon
                  niet aan. Als je graag sterk gekruid voedsel eet en het liever
                  niet wilt laten, zorg er dan voor dat je een geneesmiddel
                  tegen diarree bij de hand hebt! Eén dosis IMODIUM® Instant orodispergeerbare tabletten of IMODIUM® Capsules
				  kan je symptomen snel verlichten.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-bij-krampen-opgeblazen-gevoel-winderigheid-bij-diarree/">
                  Wanneer ik last heb van diarree, heb ik ook krampen, voel ik
                  me opgeblazen en heb ik last van winderigheid. Wat kan ik doen
                  om al deze symptomen te behandelen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Bij veel mensen gaat diarree gepaard met andere onaangename
                  symptomen, zoals maagkrampen, winderigheid en een opgeblazen
                  gevoel. Deze worden veroorzaakt door een teveel aan gas in de
                  darmen. IMODIUM® Duo heeft het extra bestanddeel simeticon om
                  dit aan te pakken naast de behandeling van de symptomen van
                  diarree. IMODIUM® Duo is verkrijgbaar in gemakkelijk in te
                  nemen tabletvorm.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben/">
                  Wanneer ik een speech moet geven, word ik zo zenuwachtig dat
                  ik maagproblemen krijg. Hoe komt dat?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Diarree die wordt veroorzaakt door angst om in het openbaar te
                  moeten presteren, komt zo vaak voor dat hij zelfs een eigen
                  naam heeft – PAD (Performance Anxiety Diarrhoea) of{" "}
                  <strong>faalangstdiarree</strong>. Als je weet dat je hieraan
                  lijdt, kan je IMODIUM® Instant orodispergeerbare tabletten
                  voorzien. Dit kan u het vertrouwen geven dat je nodig hebt om
                  het beste van uzelf te geven.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/stress-en-diarree/">
                    Stress en diarree
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/kan-imodium-verstopping-veroorzaken/">
                  Kan IMODIUM® verstopping veroorzaken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  IMODIUM® veroorzaakt zelden verstopping. Indien je jezelf aan
                  de aanbevolen dosis houdt, kan IMODIUM® je doeltreffend verlossen 
				  van diarree, vaak al met één dosis. Denk er echter aan dat je 
				  darm bij diarree vaak helemaal leeg is. Daarna kan het één à twee 
				  dagen duren voor deze zich opnieuw vult.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/gebruik-van-imodium-bij-zwangerschap-of-borstvoeding/">
                  Mag ik IMODIUM® gebruiken als ik zwanger ben of borstvoeding
                  geef?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Het is niet aanbevolen IMODIUM® te gebruiken tijdens
                  zwangerschap of borstvoeding. Vraag je arts of apotheker om
                  een alternatieve behandeling.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/zijn-orale-rehydratatieoplossingen-een-behandeling-voor-diarree/">
                  Zijn orale rehydratatieoplossingen een behandeling voor
                  diarree?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Orale rehydratatieoplossingen zijn bedoeld om verloren vocht
                  en elektrolyten (‘zouten’) in het lichaam te vervangen om
                  dehydratatie te voorkomen. Ze behandelen de symptomen van
                  diarree niet.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij het hoofdstuk&nbsp;
                  <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                    Oorzaken van diarree
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/mogen-kinderen-imodium-gebruiken/">
                  Mogen kinderen IMODIUM® gebruiken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Alleen kinderen vanaf 6 jaar mogen IMODIUM® gebruiken. Bij
                  kinderen jonger dan 6 jaar kan je orale
                  rehydratatieoplossingen gebruiken om uitdrogingsverschijnselen
                  door diarree te voorkomen. Meer informatie hierover vind je in
                  het hoofdstuk{" "}
                  <a href="/diarree-bij-kinderen/">Diarree bij kinderen</a>.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken/">
                  Is het niet beter diarree zijn gang te laten gaan om van de
                  microben af te raken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Sommige mensen denken dat diarree een middel is waarmee het
                  lichaam de microben 'wegspoelt' die volgens hen de diarree
                  veroorzaken, en dat geneesmiddelen tegen diarree werken als
                  een kurk, waardoor de microben in het lichaam blijven zitten.
                </p>
                <p>
                  <strong>Dit strookt echter niet met de werkelijkheid.</strong>
                </p>
                <p>
                  Hoewel microben of eigenlijk bacteriën diarree kunnen
                  veroorzaken, is de oorzaak bij veel mensen gewoon stress of
                  angst, of gewoon een verandering van klimaat. Als er bacteriën
                  aanwezig zijn, pakt het immuunsysteem van het lichaam deze
                  meestal wel aan. Maar intussen kan IMODIUM® ervoor zorgen dat
                  je jezelf&nbsp; beter voelt en je je gewone activiteiten kunt
                  voortzetten.
                </p>
                <p>
                  Door IMODIUM® te gebruiken, kunt u helpen het verlies van
                  waterige stoelgang te voorkomen. Deze zorgt namelijk voor
                  verlies van vitale vloeistoffen en voedingsstoffen uit je
                  lichaam. Als de symptomen echter blijven aanhouden, moet je
                  advies inwinnen bij je dokter of apotheker.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/heb-ik-een-voorschrift-nodig-voor-imodium/">
                  Heb ik een voorschrift nodig voor IMODIUM®?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Neen, IMODIUM® capsules, orodispergeerbare tabletten en
                  IMODIUM®&nbsp; Duo zijn zonder voorschrift verkrijgbaar bij de
                  apotheker.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-is-diarree/">
                  Wat is diarree?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Wanneer je spijsvertering normaal werkt, gaan voedsel en
                  vloeistoffen van de maag naar de dunne darm en vervolgens naar
                  de dikke darm. De dunne en de dikke darm zijn aan de
                  binnenkant voorzien van cellen die de nutriënten
                  (voedingsstoffen) en het water absorberen die het lichaam
                  nodig heeft.
                </p>
                <p>
                  De rest van het voedsel en het water verlaat vervolgens het
                  lichaam in de vorm van stoelgang.
                  <br />
                  Wanneer deze cellen geïrriteerd raken, kan de normaal gezien
                  trage, golfachtige beweging van de darmen overactief worden,
                  waardoor voedsel en vloeistoffen te snel door de dikke darm
                  gaan, waardoor er minder door het lichaam wordt geabsorbeerd.
                  Het resultaat hiervan is waterachtige stoelgang, beter gekend
                  als diarree.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                    Oorzaken van diarree
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-is-de-oorzaak-van-acute-diarree/">
                  Waardoor wordt acute (kortstondige) diarree veroorzaakt?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  <strong>Acute diarree </strong>kan worden veroorzaakt door
                  veel verschillende oorzaken. Enkele voorbeelden:
                </p>
                <ul>
                  <li>Virale of bacteriële infectie</li>
                  <li>
                    Allergie of intolerantie voor bepaalde voedingsmiddelen
                  </li>
                  <li>Te veel sterk gekruid of vet voedsel eten</li>
                  <li>Te veel alcohol drinken</li>
                  <li>Stress</li>
                  <li>Menstruatie</li>
                </ul>
                <p>
                  Voor meer informatie over acute diarree en de behandeling
                  ervan, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/">Feiten over diarree</a>.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/moet-ik-diarree-behandelen/">
                  Moet ik diarree behandelen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Kortstondige aanvallen van diarree kan je zelf behandelen,
                  tenzij je bijkomende symptomen krijgt zoals koorts, bloed of
                  slijm in je stoelgang; in dat geval moet je een arts
                  raadplegen. Symptomen van diarree kunnen worden verlicht door
                  het gebruik van{" "}
                  <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                    IMODIUM® Capsules
                  </a>
                  ,{" "}
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant orodispergeerbare tabletten
                  </a>{" "}
                  of{" "}
                  <a href="/imodium-behandelen-diarree/imodium-duo/">
                    IMODIUM® Duo tabletten
                  </a>
                  , die vaak verlichting kunnen bieden in slechts één dosis.
                </p>
                <p>
                  IMODIUM® biedt u een uitgebreid gamma geneesmiddelen aan voor
                  uiteenlopende situaties:
                </p>
                <p>
                  Geneesmiddel zonder voorschrift verkrijgbaar in de apotheek.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Geneesmiddel</th>
                      <th>Beschikbare verpakkingsformaten</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IMODIUM® Instant&nbsp;orodispergeerbare tabletten</td>
                      <td>20, 60</td>
                    </tr>
                    <tr>
                      <td>IMODIUM® Duo tabletten</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>IMODIUM® Capsules</td>
                      <td>20, 60, 200</td>
                    </tr>
                  </tbody>
                </table>
                
                <p />
              </div>
            </div>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>                        
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Michel de Braeystraat 52, 2000 Antwerpen, België. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
